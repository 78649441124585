import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, scrollSpy } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  scrollSpy('.header-nav-sub:visible', { offset: 0 })
})

$(window).scroll(function () {
  const scroll = $(window).scrollTop()
  if (scroll > 0) {
    $('#nav').addClass('active')
  } else {
    $('#nav').removeClass('active')
  }
})

let distance = 0

if ($('.sustainability').offset()) {
  distance = $('.sustainability').offset().top - 100
}

$(window).scroll(function () {
  if (distance === 0) {
    return
  }
  if ($(window).scrollTop() >= distance) {
    $('.sustainability').addClass('affix')
  } else {
    $('.sustainability').removeClass('affix')
  }
})
